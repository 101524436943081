import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import "../styles/index.css";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>DEP Systems</title>
      </Helmet>
      <div
        style={{
          alignItems: `center`,
          backgroundColor: `#393e46`,
          display: `flex`,
          flexDirection: `column`,
          height: `100vh`,
          justifyContent: `center`,
        }}
      >
        <StaticImage
          src="../images/logo_transparent.png"
          alt="DEP Systems"
          placeholder="blurred"
          height={400}
          width={400}
        />
      </div>
    </>
  );
};

export default IndexPage;
